import React, { useState, useRef, useCallback } from 'react';
import './index.css';
import EXIF from 'exif-js';

const aspectRatios = {
  '1:1': { width: 1, height: 1 },
  '4:3': { width: 4, height: 3 },
  '3:2': { width: 3, height: 2 },
  '16:9': { width: 16, height: 9 },
  '2:1': { width: 2, height: 1 },
  '2:3': { width: 2, height: 3 },
  '4:5': { width: 4, height: 5 },
  '9:16': { width: 9, height: 16 },
};

const PhotoFramingApp = () => {
  const [photos, setPhotos] = useState([]);
  const [selectedFormat, setSelectedFormat] = useState('1:1');
  const [padding, setPadding] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [borderColor, setBorderColor] = useState('#FFFFFF'); // Add this line
  const fileInputRef = useRef(null);
  const [includeExif, setIncludeExif] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showPreview, setShowPreview] = useState(false);

  const handlePhotoUpload = (event) => {
    const files = Array.from(event.target.files);
    const newPhotos = files.map(file => {
      const url = URL.createObjectURL(file);
      return new Promise((resolve) => {
        // Check if file is PNG
        const isPNG = file.type === 'image/png';
        console.log('File type:', file.type);

        if (isPNG) {
          console.log('PNG file detected, extracting available metadata...');
          
          // Check for Ricoh GR IIIx filename pattern
          const isRicohFile = /^R\d{7}/.test(file.name);
          const baseModel = isRicohFile ? "RICOH GR IIIx HDF" : "Unknown Camera";
          
          // Try to extract date from filename first (common format: YYYYMMDD)
          let dateFromFilename = file.name.match(/(\d{8})/);
          let formattedDate;
          
          if (dateFromFilename) {
            const date = dateFromFilename[1];
            formattedDate = `${date.slice(0,4)}:${date.slice(4,6)}:${date.slice(6,8)} 12:00:00`;
          } else if (isRicohFile) {
            // For Ricoh files without date in filename, use last modified
            const lastModified = new Date(file.lastModified);
            // Format the date in the same way as EXIF data
            formattedDate = lastModified.toISOString()
              .replace('T', ' ')
              .split('.')[0];
          } else {
            // Fallback for other files
            const lastModified = new Date(file.lastModified);
            formattedDate = lastModified.toISOString()
              .replace('T', ' ')
              .split('.')[0];
          }
          
          const img = new Image();
          img.onload = () => {
            const exifData = {
              DateTime: formattedDate,
              Model: `${baseModel} (Edited in Lightroom)`,
              ExposureTime: { numerator: 1, denominator: 125 },
              FNumber: { numerator: 28, denominator: 10 },
              ISOSpeedRatings: file.name.includes('ISO') ? 
                file.name.match(/ISO(\d+)/)?.[1] : '200',
            };

            resolve({
              original: url,
              framed: null,
              name: file.name,
              exif: exifData
            });
          };
          img.src = url;
        } else {
          // Original EXIF handling for JPEG files
          EXIF.getData(file, function() {
            let exifData = EXIF.getAllTags(this);
            console.log('Raw EXIF Data:', exifData);
            
            if (!exifData || Object.keys(exifData).length === 0) {
              console.log('No EXIF found, using file properties...');
              exifData = {
                DateTime: new Date(file.lastModified).toISOString().replace('T', ' ').split('.')[0],
                Model: 'Edited in Lightroom',
                ExposureTime: { numerator: 1, denominator: 125 }, // example value
                FNumber: { numerator: 28, denominator: 10 },      // f/2.8
                ISOSpeedRatings: '200'
              };
            }

            resolve({
              original: url,
              framed: null,
              name: file.name,
              exif: exifData
            });
          });
        }
      });
    });

    Promise.all(newPhotos).then(resolvedPhotos => {
      setPhotos([...photos, ...resolvedPhotos]);
    });
  };

  const framePhoto = useCallback((photo) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const ratio = aspectRatios[selectedFormat];
        
        // Check if image is vertical
        const isVertical = img.height > img.width;
        
        let frameWidth = Math.max(img.width, img.height * (ratio.width / ratio.height));
        let frameHeight = Math.max(img.height, img.width * (ratio.height / ratio.width));
        
        // Adjust frame size for vertical images to ensure text fits
        if (isVertical) {
          frameHeight = frameHeight * 1.1; // Add 10% extra height for text
        }
        
        const paddingFactor = 1 + (padding / 10);
        canvas.width = frameWidth * paddingFactor;
        canvas.height = frameHeight * paddingFactor;
        
        ctx.fillStyle = borderColor;
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        
        const x = (canvas.width - img.width) / 2;
        const y = (canvas.height - img.height) / 2;
        ctx.drawImage(img, x, y, img.width, img.height);
        
        if (includeExif) {
          ctx.fillStyle = 'black';
          // Adjust font size based on frame width for vertical images
          const baseFontSize = isVertical 
            ? Math.max(40, Math.min(60, frameWidth / 25))
            : Math.max(50, Math.min(80, Math.sqrt(canvas.width * canvas.height) / 60));
          
          ctx.font = `${baseFontSize}px "Courier New", monospace`;
          
          const exposureTime = photo.exif.ExposureTime;
          const exposureStr = exposureTime ? 
            `${exposureTime.numerator}/${exposureTime.denominator}s` : 'Unknown';
          
          const fNumber = photo.exif.FNumber;
          const fNumberStr = fNumber ? 
            `f/${(fNumber.numerator / fNumber.denominator).toFixed(1)}` : 'Unknown';
          
          const iso = photo.exif.ISOSpeedRatings || 'Unknown';
          
          const dateTime = photo.exif.DateTime || 'Unknown';
          const formattedDateTime = dateTime.replace(/(\d{4}:\d{2}:\d{2}) (\d{2}:\d{2}:\d{2})/, '$1    $2');
          
          const exposureInfo = `${exposureStr}  ${fNumberStr}  ISO${iso}`;
          const modelInfo = `${photo.exif.Model?.trim() || 'Unknown'}`;
          const dateInfo = formattedDateTime;
          
          const lineSpacing = baseFontSize * (isVertical ? 1.5 : 1.8);
          
          // Calculate text positioning variables
          const rightEdgeOfImage = x + img.width;
          const bottomEdgeOfImage = y + img.height;
          const textRightMargin = baseFontSize; // Scale margin with font size
          
          ctx.textAlign = 'right';
          
          // Position text relative to bottom of image
          const textY = isVertical 
            ? bottomEdgeOfImage + (lineSpacing * 1.2)
            : bottomEdgeOfImage + lineSpacing;
          
          ctx.fillText(modelInfo, rightEdgeOfImage - textRightMargin, textY);
          ctx.fillText(exposureInfo, rightEdgeOfImage - textRightMargin, textY + lineSpacing);
          ctx.fillText(dateInfo, rightEdgeOfImage - textRightMargin, textY + (lineSpacing * 2));
        }
        
        canvas.toBlob((blob) => {
          resolve(blob);
        }, 'image/jpeg', 0.9);
      };
      img.src = photo.original;
    });
  }, [selectedFormat, padding, borderColor, includeExif]);

  const handleFramePhotos = async () => {
    setIsProcessing(true);
    const framedPhotos = [];
    for (const photo of photos) {
      const framedBlob = await framePhoto(photo);
      framedPhotos.push({
        ...photo,
        framed: framedBlob,
        framedUrl: URL.createObjectURL(framedBlob)
      });
    }
    setPhotos(framedPhotos);
    setIsProcessing(false);
  };

  const handleDownload = (photo) => {
    if (photo.framed) {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(photo.framed);
      link.download = `framed_${photo.name}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    }
  };

  const handleBulkDownload = async () => {
    const JSZip = (await import('jszip')).default;
    const zip = new JSZip();
    
    for (const photo of photos) {
      if (photo.framed) {
        zip.file(`framed_${photo.name}`, photo.framed);
      }
    }
    
    const content = await zip.generateAsync({ type: "blob" });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(content);
    link.download = "framed_photos.zip";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  };

  const handleInstagramShare = async (photo) => {
    if (photo.framedUrl) {
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      
      if (isIOS) {
        const copied = await copyImageToClipboard(photo.framedUrl);
        if (copied) {
          const instagramUrl = `instagram-stories://share?source_application=your_app_id`;
          window.location.href = instagramUrl;
          alert('The framed image has been copied to your clipboard. Please paste it into your Instagram story.');
        } else {
          alert('Failed to copy the image. Please try again.');
        }
      } else {
        navigator.clipboard.writeText(photo.framedUrl).then(() => {
          alert('Image URL copied to clipboard. You can paste this into Instagram to share.');
        }).catch(err => {
          console.error('Failed to copy URL: ', err);
          alert('Failed to copy image URL. Please try again.');
        });
      }
    }
  };

  const copyImageToClipboard = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      await navigator.clipboard.write([
        new ClipboardItem({
          [blob.type]: blob
        })
      ]);
      return true;
    } catch (err) {
      console.error('Failed to copy image: ', err);
      return false;
    }
  };

  const handleRemovePhoto = (indexToRemove) => {
    setPhotos(photos.filter((_, index) => index !== indexToRemove));
  };

  const handleImageClick = (photo) => {
    setSelectedImage(photo);
    setShowPreview(true);
  };

  const ImagePreviewModal = ({ photo, onClose }) => {
    if (!photo) return null;
    
    return (
      <div className="fixed inset-0 bg-black bg-opacity-80 z-50 flex items-center justify-center p-4">
        <div className="bg-gray-800 rounded-lg p-6 max-w-[90vw] max-h-[90vh] w-full overflow-auto">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-xl font-semibold text-gray-200">Image Preview</h3>
            <button 
              onClick={onClose}
              className="text-gray-400 hover:text-gray-200 transition-colors"
            >
              ✕
            </button>
          </div>
          <div className="flex flex-col items-center">
            <img 
              src={photo.framedUrl || photo.original} 
              alt="Preview" 
              className="max-h-[70vh] w-auto object-contain rounded-lg mb-4"
            />
            <div className="text-gray-300 space-y-2 w-full">
              <p>Filename: {photo.name}</p>
              <p>Format: {photo.exif.Model}</p>
              <p>Date: {photo.exif.DateTime}</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gray-900">
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-4xl font-bold mb-12 text-gray-100 text-center">Photo Framing</h1>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12">
          <div className="bg-gray-800 rounded-lg p-6 shadow-lg">
            <h2 className="text-xl font-semibold text-gray-200 mb-4">Upload</h2>
            <button 
              onClick={() => fileInputRef.current.click()}
              className="w-full bg-gray-700 hover:bg-gray-600 text-gray-200 font-medium py-3 px-4 rounded-lg transition-colors"
            >
              Select Photos
            </button>
            <input
              type="file"
              ref={fileInputRef}
              onChange={handlePhotoUpload}
              multiple
              accept="image/*"
              className="hidden"
            />
          </div>

          <div className="bg-gray-800 rounded-lg p-6 shadow-lg">
            <h2 className="text-xl font-semibold text-gray-200 mb-4">Format</h2>
            <select 
              value={selectedFormat} 
              onChange={(e) => setSelectedFormat(e.target.value)}
              className="w-full bg-gray-700 text-gray-200 py-3 px-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-600"
            >
              {Object.keys(aspectRatios).map(ratio => (
                <option key={ratio} value={ratio}>{ratio}</option>
              ))}
            </select>
          </div>

          <div className="bg-gray-800 rounded-lg p-6 shadow-lg">
            <h2 className="text-xl font-semibold text-gray-200 mb-4">Settings</h2>
            <div className="space-y-4">
              <div>
                <label className="block text-gray-300 mb-2">Frame Padding: {padding}</label>
                <input 
                  type="range" 
                  min="0" 
                  max="10" 
                  value={padding} 
                  onChange={(e) => setPadding(parseInt(e.target.value))} 
                  className="w-full"
                />
              </div>
              <div>
                <label className="block text-gray-300 mb-2">Border Color</label>
                <input 
                  type="color" 
                  value={borderColor} 
                  onChange={(e) => setBorderColor(e.target.value)} 
                  className="w-full h-10 rounded-lg"
                />
              </div>
              <div className="flex items-center">
                <input 
                  type="checkbox" 
                  id="includeExif"
                  checked={includeExif}
                  onChange={(e) => setIncludeExif(e.target.checked)} 
                  className="mr-2"
                />
                <label htmlFor="includeExif" className="text-gray-300">Include EXIF Data</label>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-center gap-4 mb-12">
          <button 
            onClick={handleFramePhotos} 
            className="bg-gray-700 hover:bg-gray-600 text-gray-200 font-medium py-3 px-8 rounded-lg transition-colors disabled:opacity-50"
            disabled={isProcessing || photos.length === 0}
          >
            {isProcessing ? 'Processing...' : 'Frame Photos'}
          </button>
          <button 
            onClick={handleBulkDownload} 
            className="bg-gray-700 hover:bg-gray-600 text-gray-200 font-medium py-3 px-8 rounded-lg transition-colors disabled:opacity-50"
            disabled={!photos.some(p => p.framed)}
          >
            Download All
          </button>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
          {photos.map((photo, index) => (
            <div key={index} className="bg-gray-800 rounded-lg overflow-hidden shadow-lg">
              <div 
                className="relative cursor-pointer aspect-square"
                onClick={() => handleImageClick(photo)}
              >
                <img
                  src={photo.framedUrl || photo.original}
                  alt={`Photo ${index + 1}`}
                  className="w-full h-full object-contain"
                />
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemovePhoto(index);
                  }}
                  className="absolute top-2 right-2 bg-gray-900 bg-opacity-50 hover:bg-opacity-75 text-white rounded-full p-1"
                >
                  ✕
                </button>
              </div>
              <div className="p-4">
                <div className="flex space-x-2">
                  <button 
                    onClick={() => handleDownload(photo)} 
                    disabled={!photo.framed}
                    className="flex-1 bg-gray-700 hover:bg-gray-600 text-gray-200 py-2 px-3 rounded transition-colors disabled:opacity-50"
                  >
                    Download
                  </button>
                  <button 
                    onClick={() => handleInstagramShare(photo)} 
                    disabled={!photo.framedUrl}
                    className="flex-1 bg-gray-700 hover:bg-gray-600 text-gray-200 py-2 px-3 rounded transition-colors disabled:opacity-50"
                  >
                    Share
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>

        {showPreview && (
          <ImagePreviewModal
            photo={selectedImage}
            onClose={() => {
              setShowPreview(false);
              setSelectedImage(null);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default PhotoFramingApp;
